import { IBasicImage } from './BasicImage';

export interface INavLink {
  slug: string;
  id: number;
  url?: string;
}
export interface IMainNavigation{
  elementId: number;
  title: string;
  nodeUri: string;
  parent?: IMainNavigation;
}
export interface IMenu {
  id: number;
  title?: string;
  slug?: string;
  submenu?: IMenu[];
}
export interface IBioContent {
  id: number;
  bioName: string;
  bioPosition: string;
  bioEmail: string;
  bioBody: string;
  bioPicture: IBasicImage[];
}
export interface IProjects {
  id: number;
  blockTitle?: string;
  blockCopy?: string;
  blockRegularDocument?: IUrl[];
  blockFeaturedCopy?: string;
  blockFeaturedTitle?: string;
  blockFeaturedLink?: ILink[];
  blockFeaturedOutbound?: string;
  blockFeaturedDocument?: IUrl[];
  blockRegularLink?: ILink[];
  blockFeaturedImage?: IBasicImage[];
  blockRegularCopy?: string;
  blockRegularOutbound?: string;
  blockRegularTitle?: string;
  sectionTitle?: string;
  isSectionTitleLarge?: boolean;
}
export interface IGrid {
  gridTitle: string;
  gridImage: IGridImage;
  gridContent: string;
  gridLink: IGridLink[];
}
export interface IGridImage {
  [index: number]: {
    id: number;
    uri: string;
    url: string;
    title: string;
    filename: string;
    folderId: number;
    slug: string;
    volumeId: string;
  };
}

export interface ILink {
  url: string;
  slug?: string;
  id: number;
  uri?:string;
}

export interface IGridLink {
  uri: string;
  url?: string;
  id: number;
  slug?: string;
}

export interface IUrl {
  url: string;
}

export interface IImage {
  url: string;
  title: string;
  width?: number;
  height?: number;
}

export enum Colors {
  Blue,
  Red,
  Green,
}

export enum CraftPageType {
  Default = 'default',
  SideBySide = 'sidebyside',
  Blocks = 'blocks',
}
