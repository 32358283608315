import React from 'react';
import tw, { styled } from 'twin.macro';
import { Colors } from '../common';

export interface IButtonWrapper {
  stxColor?: Colors;
  children?: React.ReactNode;
  floatPos?: string;
}
export const ButtonWrapper = styled.div<IButtonWrapper>(({ stxColor, floatPos }) => {
  const out = [
    tw`border border-white inline-block`,
    {
      float: floatPos !== undefined ? floatPos : 'none',
      maxWidth: 175,
    },
  ];
  if (stxColor === Colors.Blue) {
    out.push(tw`bg-bordeaux`);
  }

  return out;
});

export const Btn = tw`hover:bg-black-dark bg-black-light px-4 py-2 block`;

export const Txt = styled.span(() => tw`text-white text-center font-medium text-xl`);
